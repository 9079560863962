import { forwardRef, useState } from "react";
import { Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Tooltip } from "@chakra-ui/react";

const AMSlider = forwardRef((props: any, ref: any): any => {
  const {
    id,
    callback,
    defaultValue,
    min,
    max,
    step,
    defaultTooltipState = false,
    sliderMarks,
    updatedSliderValue,
    register,
    isDisabled = false,
    ...rest
  }: any = props;

  // const [sliderValue, setSliderValue] = useState(defaultValue)
  const [showTooltip, setShowTooltip] = useState(defaultTooltipState)

  const parseAndSetSliderValue = (v: any) => {
    // callback
    // setSliderValue(v)
  };

  return (
    <Slider
      id={id}
      w='85%'
      m='0 auto'
      ref={ref}
      defaultValue={defaultValue}
      min={min}
      max={max}
      step={step}
      colorScheme='brand'
      isDisabled={isDisabled}
      value={updatedSliderValue ? updatedSliderValue : defaultValue}
      // onChange={(v) => setSliderValue(updatedSliderValue)}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
      {...rest}
    >
      {sliderMarks && sliderMarks.map((sliderMark: any, sliderMarkIDx: number) => {
        return (
          <SliderMark key={sliderMarkIDx} value={sliderMark.value} mt='4' ml='-2' fontSize='md'>
            {sliderMark.label}
          </SliderMark>
        )
      })}
      <SliderTrack>
        <SliderFilledTrack />
      </SliderTrack>
      <Tooltip
        hasArrow
        bg='brand.600'
        color='#000'
        placement='top'
        isOpen={showTooltip}
        label={`${updatedSliderValue ? updatedSliderValue : defaultValue}%`}
      >
        <SliderThumb
          boxSize={{ base: 8, md: 6, lg: 6 }}
          bg='brand.600' />
      </Tooltip>
    </Slider>
  )
})

export default AMSlider;