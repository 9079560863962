const serialize = JSON.stringify;
const deserialize = JSON.parse;
const storage = window.localStorage;

export const saveDataInStorage = (key: string, data: object | string | number) => {
  try {
    const serializedState = serialize(data);
    storage.setItem(key, serializedState);
  } catch (error) {
    console.error('error while saving data in localStorage', error);
  }
}

export const fetchDataFromStorage = (key: string) => {
  try {
    const serializedState = storage.getItem(key);
    if (serializedState === null) {
      return {};
    }
    return deserialize(serializedState);
  } catch (error) {
    console.error('error while fetching data from localStorage', error);
    return {};
  }
}

export const clearDataFromStorage = (key: string) => {
  storage.removeItem(key)
}

export const clearAllFromStorage = () => {
  storage.clear();
}