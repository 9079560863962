import HTTP from "./http";

const API_URL = process.env.REACT_APP_NODE_ENV
const URL_PREFIX = 'broker/';
const BASE_URLS = {
  SEARCH: 'search',
  TEST_BROKER_CONNECTION: 'test-broker-connection',
  UPSERT_TO_USER: 'upsert-to-user',
}

const {
  SEARCH,
  TEST_BROKER_CONNECTION,
  UPSERT_TO_USER,
} = BASE_URLS;

const BrokerService = {
  async getBrokers({ page = 0, size = 10 }) {
    const url = `${API_URL}${URL_PREFIX}${SEARCH}`;
    const { data: response } = await HTTP.post(`${url}`, {
      page,
      size
    });
    return response;
  },

  // broker connection credentials check
  async upsertToUser(data) {
    const url = `${API_URL}${URL_PREFIX}${UPSERT_TO_USER}`;
    const { data: response } = await HTTP.post(`${url}`, data);
    return response;
  },

  async testBrokerConnection(brokerName) {
    const url = `${API_URL}${URL_PREFIX}${TEST_BROKER_CONNECTION}`;
    const { data: response } = await HTTP.post(`${url}?brokerSlug=${brokerName}`);
    return response;
  },
}

export default BrokerService