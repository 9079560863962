import React from "react";

const AuthContext = React.createContext({
  user: null,
  token: null,
  role: null,
  permissions: null,
  setUser: () => { },
  loading: null,
  isLoggedIn: null,
  updateUserInfo: () => { },
});

export default AuthContext;
