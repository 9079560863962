// kindly follow the below pattern to store constants of validation messages and their paths

const VALIDATION_ACTIONS = {
  EMAIL: {
    REQUIRED: 'EMAIL.REQUIRED',
    INVALID: 'EMAIL.INVALID',
  },
  PASSWORD: {
    REQUIRED: 'PASSWORD.REQUIRED'
  },
  REFERRAL_CODE: {
    REQUIRED: 'REFERRAL_CODE.REQUIRED'
  },
  PHONE_NUMBER: {
    REQUIRED: 'PHONE_NUMBER.REQUIRED'
  },
  OTP: {
    REQUIRED: 'PHONE_NUMBER.REQUIRED'
  },
  CLIENT_CODE: {
    REQUIRED: 'CLIENT_CODE.REQUIRED'
  },
  PIN: {
    REQUIRED: 'PIN.REQUIRED'
  },
  API_KEY: {
    REQUIRED: 'API_KEY.REQUIRED'
  },
  TOTP_SECRET_KEY: {
    REQUIRED: 'TOTP_SECRET_KEY.REQUIRED'
  }
}

const VALIDATION_MESSAGES: Record<string, any> = {
  EMAIL: {
    INVALID: 'Enter a valid email address',
    REQUIRED: 'This field is required.'
  },
  PASSWORD: {
    REQUIRED: 'This field is required.'
  },
  REFERRAL_CODE: {
    REQUIRED: 'This field is required.'
  },
  PHONE_NUMBER: {
    REQUIRED: 'This field is required.'
  },
  OTP: {
    REQUIRED: 'This field is required.'
  },
  CLIENT_CODE: {
    REQUIRED: 'This field is required.'
  },
  PIN: {
    REQUIRED: 'This field is required.'
  },
  API_KEY: {
    REQUIRED: 'This field is required.'
  },
  TOTP_SECRET_KEY: {
    REQUIRED: 'This field is required.'
  }
}

export {
  VALIDATION_ACTIONS,
  VALIDATION_MESSAGES,
}