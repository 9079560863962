import { FC, ReactElement, useContext } from 'react'
import { Box, Divider, Flex, HStack, Heading, Image, Spacer, Spinner, Text } from '@chakra-ui/react'
import { MonkeyIco } from 'assets';
import { Link } from 'react-router-dom';
import { TO } from './../../constants';
import { AuthContext } from 'context';
import useLogoutService from 'hooks/useLogoutService';

const TermsAndConditions: FC = (): ReactElement => {

	const { isLoggedIn = false, loading = false }: any = useContext(AuthContext);

	const { logout } = useLogoutService();

	return (
		<Box>
			<HStack m={2}>
				<Image
					h={8}
					src={MonkeyIco} />
				<Heading>Terms and Conditions</Heading>
			</HStack>
			<Box m={2}>
				{loading
					? <Spinner size='xs' />
					: <Flex>
						<Spacer />
						{!isLoggedIn && <>
							<Link to={TO.SIGN_UP}>
								<Text color='blue'>Register</Text>
							</Link>
							<Link to={TO.LOGIN}>
								<Text ml={2} color='blue'>Sign in</Text>
							</Link>
						</>}
						{isLoggedIn && <>
							<Link to={TO.ROOT}>
								<Text ml={2} color='blue'>Home</Text>
							</Link>
							<Text ml={2} color='blue' onClick={() => logout()}>Logout</Text>
						</>}
					</Flex>}
				<Divider mt={2} mb={2} />
				<Text>The content goes here...</Text>
			</Box>
		</Box>
	)
}

export default TermsAndConditions;