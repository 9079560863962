export const getWithDefault = (object: object | undefined, path: string, defaultValue: any) => {
  if (!object) {
    return defaultValue;
  }
  return findValueByKey(object, path, defaultValue);
}

const findValueByKey = (object: any, path: string, defaultValue: string) => {
  const properties: string[] = path.split(".");
  let propertiesIterationCounter: number = 0;
  const propertiesLength: number = properties.length;
  for (propertiesIterationCounter; propertiesIterationCounter < propertiesLength; propertiesIterationCounter++) {
    if (!object) {
      return defaultValue;
    }
    else if (!object.hasOwnProperty(properties[propertiesIterationCounter])) {
      return defaultValue;
    }
    object = isBool(object[properties[propertiesIterationCounter]], defaultValue);
  }
  return object;
}

export const isEmpty = (obj: object) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export const isBool = (val: object | string | number, defaultValue: string | boolean) => {
  if (typeof val === 'boolean') {
    return val ? true : false;
  }
  if (val === null || val === undefined) {
    return defaultValue;
  }
  return val;
}