import { useEffect, useState } from 'react';
import { getWithDefault } from 'utils';
import SubscriptionService from 'services/subscription';
import { useToast } from '@chakra-ui/react';
import { CONSTANTS, MESSAGES, TO, TOAST_STATUS } from './../constants';
import { useNavigate } from 'react-router-dom';
import { AuthService } from 'services';
import { useHandleAuthValues } from 'context';

interface IStrategyParams {
  capitalUsagePercent: string;
  riskPerTradePercent: string;
  lossThresholdPercent: string;
}
interface ISubscriptionParams {
  strategySlug: string;
  brokerSlug: string;
  strategyParams: IStrategyParams;
}

const useEditSubscriptionService: any = (props: any): any => {

  const {
    slug = null,
    subscriptionSlug = null
  } = props || {};

  // lib hooks
  const toast = useToast();
  const navigate = useNavigate();
  const useHandleAuthHook = useHandleAuthValues();

  const [subscription, setSubscription] = useState<any>({
    response: null,
    error: false,
    loading: null
  });

  const [editSubscriptionRes, setEditSubscriptionRes] = useState<any>({
    response: null,
    error: false,
    loading: false
  });

  const [mySubscriptionsRes, setMySubscriptionsRes] = useState<any>({
    response: null,
    isReqCompleted: false,
    error: false,
    loading: false
  });

  // console.log('subscriptionSlug', slug, subscriptionSlug)

  useEffect(() => {
    if (subscriptionSlug) {
      setSubscription({ ...subscription, error: null, loading: true });
      SubscriptionService.getSubscriptionBySlug(subscriptionSlug ? subscriptionSlug : slug)
        .then((response: any) => {
          const { status } = response;
          if (status) {
            setSubscription({
              response: getWithDefault(response, 'data', false),
              error: false,
              loading: false
            })
          }
        })
        .catch((error: any) => {
          const { response } = error;
          setSubscription({
            error: response,
            loading: false
          });
          toast({
            description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
            status: TOAST_STATUS.ERROR
          })
        })
    }
  }, []);

  const editSubscriptionReq = (params: ISubscriptionParams) => {
    setEditSubscriptionRes({ ...editSubscriptionRes, error: null, loading: true });
    SubscriptionService.upsertSubscriptions(params)
      .then((response: any) => {
        const { status } = response;
        if (status) {
          AuthService.validateToken()
            .then((userRes: any) => {
              useHandleAuthHook.updateAuthContext(getWithDefault(userRes, 'data.data', {}), false)
                .then((response: any) => {
                  if (response.operation === CONSTANTS.AUTH_PROMISE_SUCCESSFUL) {
                    setEditSubscriptionRes({
                      response: getWithDefault(response, 'data', false),
                      error: false,
                      loading: false
                    });
                    navigate(TO.ROOT)
                  }
                })
                .catch((error: string) => {
                  console.error(MESSAGES.SOMETHING_BAD_HAPPENED, error)
                })
            })
            .catch((connectionError: any) => {
              toast({
                description: getWithDefault(connectionError, 'response.data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
                status: TOAST_STATUS.ERROR
              })
            })
        }
      })
      .catch((error: any) => {
        const { response } = error;
        setEditSubscriptionRes({
          error: response,
          loading: false
        });
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR
        })
      })
  };

  const mySubscriptionsReq = () => {
    setEditSubscriptionRes({ ...setMySubscriptionsRes, error: null, loading: true });
    SubscriptionService.mySubscriptions()
      .then((response: any) => {
        const { status } = response;
        if (status) {
          setMySubscriptionsRes({
            response: getWithDefault(response, 'data', []),
            error: false,
            loading: false,
            isReqCompleted: true,
          });
        }
      })
      .catch((error: any) => {
        const { response } = error;
        setMySubscriptionsRes({
          error: response,
          loading: false,
          isReqCompleted: true
        });
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR
        })
      })
  };

  return {
    subscription: getWithDefault(subscription, 'response', false),
    subscriptionLoading: getWithDefault(subscription, 'loading', null),
    editSubscriptionReq,
    editSubscriptionRes,
    mySubscriptionsReq,
    mySubscriptionsRes
  };
}

export default useEditSubscriptionService;