import { lazy, Suspense } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom"
import {
  Root
} from "./layouts/root"
import PrivateRoute from './components/auth/PrivateRoute';
import {
  Login,
  Signup,
  ResetPassword,
  Onboarding,
  EditSubscription,
  TermsAndConditions
} from 'pages';
import { AuthContext, AuthProvider } from './context';
import { CONSTANTS, TO } from './constants';
import SubscribeStrategy from 'pages/strategy/subscribe';

const Dashboard = lazy(() => import('./pages/dashboard'))

const routes = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      {/* <Route path={TO.LOGIN} element={
        isEmpty(fetchDataFromStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY)) ? (
          <Login />
        ) : (
          <Navigate replace to={TO.ROOT} />
        )
      } /> */}
      <Route path={TO.LOGIN} element={
        <Login />
      } />
      {/* <Route path={TO.SIGN_UP}>
        <Route path=":referredBy?" element={<Signup />} />
      </Route> */}
      {/* <Route path={`${TO.SIGN_UP}?referredBy=`} element={
        <Signup />
      } /> */}
      <Route path={TO.SIGN_UP}>
        <Route path={`:referredBy?`} element={
          <Signup />
        } />
      </Route>
      <Route path={TO.FORGOT_PASSWORD} element={
        <ResetPassword />
      } />
      <Route path={TO.TERMS_AND_CONDITIONS} element={
        <TermsAndConditions />
      } />
      <Route element={<Root />}>
        <Route
          path={TO.ROOT}
          element={
            <Suspense fallback={<>{CONSTANTS.LOADING}</>}>
              <PrivateRoute component={Dashboard} />
            </Suspense>
          }>
        </Route>
        <Route
          path={TO.ONBOARDING}
          element={
            <Suspense fallback={<>{CONSTANTS.LOADING}</>}>
              <PrivateRoute component={Onboarding} />
            </Suspense>
          }>
        </Route>
        <Route
          path={TO.SUBSCRIBE_STRATEGY}
          element={
            <Suspense fallback={<>{CONSTANTS.LOADING}</>}>
              <PrivateRoute component={SubscribeStrategy} />
            </Suspense>
          }>
        </Route>
        <Route
          path={`${TO.EDIT_SUBSCRIPTION}/:slug`}
          element={
            <Suspense fallback={<>{CONSTANTS.LOADING}</>}>
              <PrivateRoute component={EditSubscription} />
            </Suspense>
          }
        />
      </Route>
    </Route>
  )
)

export const App = () => (
  <AuthContext.Provider value={AuthProvider()} >
    <RouterProvider router={routes} />
  </AuthContext.Provider >
)
