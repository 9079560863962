type IFormFieldProps = {
    NAME: string;
    PLACEHOLDER: string;
    TYPE: string;
}

const USER: Record<string, IFormFieldProps> = {
    EMAIL: {
        NAME: 'email',
        PLACEHOLDER: 'Email',
        TYPE: 'email'
    },
    PHONE: {
        NAME: 'phoneNumber',
        PLACEHOLDER: 'Phone',
        TYPE: 'number'
    },
    PASSWORD: {
        NAME: 'password',
        PLACEHOLDER: 'Password',
        TYPE: 'password'
    },
    NEW_PASSWORD: {
        NAME: 'password',
        PLACEHOLDER: 'New password',
        TYPE: 'password'
    }
}

const SIGNUP = {
    REFERRAL_CODE: {
        NAME: 'referralCode',
        PLACEHOLDER: 'Referral Code',
        TYPE: 'text'
    }
}

const ONBOARDING = {
    BROKER: {
        API_KEY: {
            NAME: 'apiKey',
            PLACEHOLDER: 'API Key',
            TYPE: 'text'
        },
        CLIENT_CODE: {
            NAME: 'clientCode',
            PLACEHOLDER: 'Client Code',
            TYPE: 'text'
        },
        PIN: {
            NAME: 'pin',
            PLACEHOLDER: 'Pin',
            TYPE: 'password'
        },
        TOTP_SECRET: {
            NAME: 'totpSecret',
            PLACEHOLDER: 'Totp Secret',
            TYPE: 'text'
        }
    }
}

const ACTIONS = {
    TEST_CONNECTION: 'Test Connection',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    SAVE_AND_CONTINUE: 'Save and Continue',
    SUBSCRIBE: 'Subscribe'
}

const SLIDER_PROPS = {
  CAPITAL_USAGE: {
    ID: 'capital-usage-slider',
    MIN: 5,
    MAX: 100,
    STEP: 5,
    SLIDER_MARKS: [
      {
        value: 5,
        label: '5%',
      },
      {
        value: 25,
        label: '25%',
      },
      {
        value: 50,
        label: '50%',
      },
      {
        value: 75,
        label: '75%',
      },
      {
        value: 100,
        label: '100%',
      },
    ],
  },
  RISK_PER_TRADE: {
    ID: 'risk-per-trade-slider',
    MIN: 0.25,
    MAX: 4,
    STEP: 0.25,
    SLIDER_MARKS: [
      {
        value: 0.25,
        label: '0.25%',
      },
      {
        value: 1,
        label: '1%',
      },
      {
        value: 2,
        label: '2%',
      },
      {
        value: 3,
        label: '3%',
      },
      {
        value: 4,
        label: '4%',
      },
    ],
  },
  LOSS_THRESHOLD: {
    ID: 'loss-threshold-slider',
    MIN: 5,
    MAX: 100,
    STEP: 5,
    SLIDER_MARKS: [
      {
        value: 5,
        label: '5%',
      },
      {
        value: 25,
        label: '25%',
      },
      {
        value: 50,
        label: '50%',
      },
      {
        value: 75,
        label: '75%',
      },
      {
        value: 100,
        label: '100%',
      },
    ],
  },
};

export {
    USER,
    SIGNUP,
    ONBOARDING,
    ACTIONS,
    SLIDER_PROPS
}