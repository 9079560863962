import { FC, ReactElement } from 'react';
import { Box, Stack, Text, Spacer, Image, HStack, Divider, useToast, Center } from '@chakra-ui/react'
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { AuthLayout } from './../../layouts';
import { AMButton } from './../../components/generic'
import { ThemeInput } from './../../components/form';
import { AuthService } from './../../services'
import { useHandleAuthValues } from './../../context';
import { CONSTANTS, P_LOGIN, USER, P_GENERAL, TO, TOAST_STATUS, MESSAGES } from "./../../constants/";
import { saveDataInStorage, getWithDefault } from '../../utils';
import {
  ISuccess,
  LoginFormSubmitValues
} from './_types';
import {
  GeneralTypes
} from './../../types';
import AMLogo from './../../assets/images/logo.png';
import { LoginValidationSchema } from 'utils/_validations';

const Login: FC = (): ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  }: any = useForm<LoginFormSubmitValues>({
    resolver: yupResolver(LoginValidationSchema())
  });

  // lib hooks
  const navigate = useNavigate();
  const toast = useToast();

  // custom hooks
  const useHandleAuthHook = useHandleAuthValues();

  const onSubmit = (values: LoginFormSubmitValues): any => {
    AuthService.signin(values)
      .then((success: ISuccess) => {
        const { status } = success;
        if (status) {
          saveDataInStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY, success.data.token)
          useHandleAuthHook.updateAuthContext(getWithDefault(success, 'data', {}), true)
            .then((response: GeneralTypes.IAuthResponse) => {
              if (response.operation === CONSTANTS.AUTH_PROMISE_SUCCESSFUL) {
                navigate(TO.ROOT)
              }
            })
            .catch((error: string) => {
              console.error(MESSAGES.SOMETHING_BAD_HAPPENED, error)
            })
        }
      })
      .catch((error: any) => {
        const { response } = error;
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR,
        })
      })
  }

  return (
    <AuthLayout>
      <Stack spacing="4" mb={2}>
        <Center>
          <Image
            h={{ base: '18px', sm: '30px' }}
            src={AMLogo} />
        </Center>
        <Divider />
        <Stack mb='4' spacing={{ base: '2', md: '3' }} textAlign="center">
          <Text
            fontSize={{ base: '3xl', sm: '4xl' }}
            fontWeight='semibold'>{P_LOGIN.HEADING}</Text>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}>{P_LOGIN.SUB_HEADING}</Text>
        </Stack>
      </Stack>
      <Box as={"form"} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={6}>
          <ThemeInput
            isRequired
            name={USER.EMAIL.NAME}
            placeholder={USER.EMAIL.PLACEHOLDER}
            type={USER.EMAIL.TYPE}
            register={register}
            error={errors[USER.EMAIL.NAME]}
          />
          <ThemeInput
            isRequired
            name={USER.PASSWORD.NAME}
            placeholder={USER.PASSWORD.PLACEHOLDER}
            type={USER.PASSWORD.TYPE}
            register={register}
            error={errors[USER.PASSWORD.NAME]}
          />
        </Stack>
        <HStack alignItems='center' justifyContent='center'>
          <Text
            fontSize='md'
            ml="2"
            color='brand.500'
            fontWeight='bold'>
            <Link to={TO.FORGOT_PASSWORD}>{P_GENERAL.FORGOT_PASSWORD}</Link>
          </Text>
          <Spacer />
          <Box
            mt="4"
            display='flex'
            justifyContent='flex-end'>
            <AMButton
              rightIcon={<ArrowForwardIcon />}
              type="submit"
              label={P_GENERAL.LOGIN}
              variant='round'
              colorScheme="brand"
              bgGradient='linear(to-r, brand.400, brand.500)'
            />
          </Box>
        </HStack>
      </Box>
      <HStack
        mt='12'
        alignItems='center'
        justifyContent='center'>
        <Text
          fontSize='md'>{P_GENERAL.NO_ACCOUNT}</Text>
        <Text
          color='brand.500'
          fontSize='md'
          fontWeight='bold'>
          <Link to={TO.SIGN_UP}>{P_GENERAL.URL_LBL_REGISTER_HERE}</Link>
        </Text>
      </HStack>
    </AuthLayout>
  )
}

export default Login;
