import { useEffect, useState } from 'react';
import StrategyService from '../strategy';

interface IAjaxResp {
  response: object | null;
  error: boolean | null;
  loading: boolean | null;
};

const useStrategyService: any = (): any => {
  const [data, setData] = useState<IAjaxResp>({
    response: null,
    error: false,
    loading: true
  });

  useEffect(() => {
    setData({ ...data, error: null, loading: true });
    StrategyService.getStrategies({
      page: 0,
      size: 10
    })
      .then((success: any) => {
        const { status } = success;
        if (status) {
          setData({
            response: success.data,
            error: !status,
            loading: false
          });
        }
      })
      .catch((error: any) => {
        const { response } = error;
        setData({
          response: response.data,
          error: response,
          loading: false
        });
      })
  }, []);

  return data;
}

export default useStrategyService;