import { ReactElement, useRef } from "react"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button
} from "@chakra-ui/react"
import './_styles.scss'
import { getWithDefault } from "utils";

const ConfirmationDialog: any = ({
  isDialogOpen = false,
  onDialogClose,
  dialogProps = {}
}: any): ReactElement => {

  const cancelRef: any = useRef<HTMLButtonElement>();

  return (
    <AlertDialog
      id='am-confirmation-dialog'
      isOpen={isDialogOpen}
      leastDestructiveRef={cancelRef}
      onClose={onDialogClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {dialogProps.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            {dialogProps.description}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onDialogClose}>
              {getWithDefault(dialogProps, 'actions.cancel', 'Cancel')}
            </Button>
            <Button colorScheme='red' onClick={dialogProps.confirmationCallback} ml={3}>
              {getWithDefault(dialogProps, 'actions.submit', 'Submit')}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default ConfirmationDialog;