import { useContext } from 'react';
import { Outlet } from 'react-router-dom'
import { Container, Flex, Spacer, Text, Center, Stack } from '@chakra-ui/react';
import AuthContext from '../../context/auth/auth-context';
import { FloatingContactSupportAction, Loader } from 'components/generic';
import { MESSAGES } from './../../constants';
import { getWithDefault } from 'utils';
import packageJson from './../../../package.json';
import Header from 'components/header';

export const Root = () => {
	const { loading = false }: any = useContext(AuthContext);

	return (
		<>
			{loading ?
				<Loader>
					{MESSAGES.VALIDATING_TOKEN}
				</Loader> :
				<Flex
					flexDir='column'
					h='100%'
					maxW={{
						base: '100%',
						sm: '100%',
						md: '100%',
						lg: '100%',
						xl: '1200px',
						'2xl': '1536px'
					}}>
					<Header />
					<Container as="main" m={0} p={0} mt="60px" h='100%' w='100%' maxW='100%'>
						<Outlet />
					</Container>
					<Spacer />
					<Stack mt={2} spacing='60px'>
						<Center
							flexDir='column'
							fontSize='sm'>
							{/* <Link to={TO.TERMS_AND_CONDITIONS}>
								<Text color='blue'>{CONSTANTS.TERMS_AND_CONDITIONS}</Text>
							</Link>
							<Text mt={2}>{CONSTANTS.COMPANY}</Text> */}
							<Text mt={2}>v{getWithDefault(packageJson, 'version', '')}</Text>
						</Center>
					</Stack>
					{/* <FloatingContactSupportAction /> */}
				</Flex>}
		</>
	)
}
