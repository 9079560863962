import { useEffect, useState } from "react";
import { fetchDataFromStorage, saveDataInStorage } from "../../utils/_localStorage";
import { CONSTANTS, TO } from "./../../constants";
import AuthService from "../../services/auth";

const routes = [TO.LOGIN, TO.SIGN_UP, TO.FORGOT_PASSWORD]

const AuthProvider = (): any => {

  const [token, setToken] = useState("");
  const [user, setUser] = useState({});
  const [role, setRole] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [requestCompleted, setRequestCompleted] = useState(false);

  useEffect(() => {
    if (!requestCompleted) {
      (async () => {
        setLoading(true);
        try {
          if (!token && !routes.includes(window.location.pathname) && typeof window !== "undefined") {
            const token = fetchDataFromStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY);
            if (token) {
              const data = await AuthService.validateToken();
              updateUserInfo(data);
            }
          }
        } catch (err: any) {
          console.error(err?.response?.data?.message);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);

  const updateUserInfo = (response: any) => {
    const token = response?.data?.token;
    const user = response?.data?.data;
    const role = response?.data?.roleName;
    const permissions = response?.data?.permissions;
    saveDataInStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY, token);
    setToken(token);
    setIsLoggedIn(true);
    setUser(user);
    setRole(role);
    setPermissions(permissions);
    setLoading(false);
    setRequestCompleted(true);
  };

  return {
    user,
    token,
    role,
    permissions,
    setUser,
    loading,
    isLoggedIn,
    updateUserInfo,
    requestCompleted,
  };
};

export default AuthProvider;
