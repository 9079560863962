import { extendTheme, type ThemeConfig } from '@chakra-ui/react'
import { styles } from './reset/styles';
import Button from "./components/button";
import colors from './brand/colors';
import typography from "./brand/typography";
import borderRadius from './brand/border-radius';
import { badgeTheme } from './components/badge';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const breakpoints = {
  base: "0px",
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
  "2xl": "1536px",
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};

const customTheme = {
  config,
  styles,
  breakpoints,
  colors,
  borderRadius,
  ...typography,
  components: {
    Input: {
      baseStyle: {
        field: {
          border: '1px solid #000',
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            'input:disabled .chakra-form__label': {
              color: 'grey',
            },
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label':
            {
              ...activeLabelStyles,
            },
            label: {
              top: '4px',
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: 'left top',
              _disabled: {
                color: 'grey',
              },
            },
          },
        },
      },
    },
    Button,
    AlertDialog: {
      defaultProps: {
        background: 'white',
      },
    },
    Badge: badgeTheme,
  },
};

const theme = extendTheme(customTheme)

export default theme

// https://chakra-ui.com/docs/styled-system/theme
// https://chakra-ui.com/docs/styled-system/customize-theme
// https://stackoverflow.com/questions/70809036/how-can-i-overwrite-styles-of-an-autofilled-input-when-using-chakra-ui
// https://chakra-ui.com/docs/styled-system/customize-theme