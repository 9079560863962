const API_URL = process.env.REACT_APP_NODE_ENV

const URLS = {
  USER: {
    PREFIX: 'user/',
    BASE_URLS: {
      DASHBOARD_DETAILS: 'dashboard-details'
    }
  },
  STRATEGY: {
    PREFIX: 'strategy/',
    BASE_URLS: {
      SEARCH: 'search'
    }
  },
  SUBSCRIPTION: {
    PREFIX: 'subscription/',
    BASE_URLS: {
      SEARCH: 'search',
      UPSERT: 'upsert',
      MY: 'my',
      PAUSE: 'pause',
      RESUME: 'resume'
    }
  },
  ALGO: {
    PREFIX: 'algo/',
    BASE_URLS: {
      SQUARE_OFF: 'square-off',
    }
  }
}

export {
  API_URL,
  URLS
}