import { useContext } from 'react';
import { useNavigate } from 'react-router';
import { AuthService } from 'services';
import { AuthContext } from 'context';
import { TO, CONSTANTS } from './../constants';
import { clearDataFromStorage } from 'utils';

const useLogoutService: any = (): any => {

  const navigate = useNavigate();
  const authContext: any = useContext(AuthContext);

  const logout = () => {
    AuthService.logout()
      .then((success: any) => {
        const { status } = success;
        if (status) {
          authContext.token = null
          authContext.isLoggedIn = false;
          authContext.user = {};
          authContext.role = null;
          authContext.permissions = null;
          authContext.loading = false;
          clearDataFromStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY)
          setTimeout(() => {
            navigate(TO.LOGIN)
          }, 1000);
        }
      })
      .catch((error: any) => {
        const { response } = error;
        alert(response.data.message)
      })
  }

  return {
    logout
  }
}

export default useLogoutService;