import { Flex, Image, Grid, GridItem, HStack, Show, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import useStrategyService from './../../../services/hooks/strategy';
import StrategyCard from '../strategy-card';
import { AMButton } from 'components/generic';
import { TO } from './../../../constants';
import { DummyAmIllus } from './../../../assets';
import { getWithDefault } from 'utils';

const SubscribeStrategy = (): any => {

  const navigate = useNavigate();
  const { response } = useStrategyService();

  return (
    <HStack w='100%' h='100%'>
      <Grid
        h='100%'
        w='100%'
        gap={{
          base: 4,
          lg: 6
        }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(2, 1fr)',
          '2xl': 'auto minmax(calc(100% - 700px), 60%)'
        }}>
        <Show above='md'>
          <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
            <Flex bgGradient='linear(to-r, brand.400, brand.500)' h='100%' alignItems='center' justifyContent='center'>
              <Image
                h={{ base: '175px', md: '400px', lg: '450px' }}
                src={DummyAmIllus} />
            </Flex>
          </GridItem>
        </Show>
        <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
          <Stack m={4}>
            {response && response.content.map((subscription: any, subscriptionIDx: number) => {
              return (
                <>
                  <StrategyCard
                    key={subscriptionIDx}
                    subscription={{
                      strategy: subscription
                    }} />
                  <AMButton size='md' variant='solid' label='Click here to configure and subscribe' onClick={() => {
                    navigate(`${TO.EDIT_SUBSCRIPTION}/${subscription.slug}`, {
                      state: {
                        // subscriptionSlug: getWithDefault(subscription, 'strategy.subscriptionSlug', null),
                        // TODO: passing complete subscription as we do not have api support to fetch strategy by id
                        // once we have tha support we pass subscriptionSlug only to validate
                        subscription: {
                          strategy: subscription
                        },
                        // to check the mode - create fresh strategy or editing existing subscribed strategy
                        subscriptionSlug: getWithDefault(subscription, 'subscriptionSlug', null)
                      }
                    })
                  }} />
                </>
              )
            })}
          </Stack>
        </GridItem>
      </Grid>
    </HStack>
  );
};

export default SubscribeStrategy;
