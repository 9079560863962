import { useContext } from 'react';
import AuthContext from './auth-context';
import { CONSTANTS } from './../../constants'

const useHandleAuthValues = () => {
  const authContext: any = useContext(AuthContext);

  const setValues = (values: any, updateToken: boolean) => {
    if (values) {
      if (!updateToken) {
        authContext.isLoggedIn = true;
        authContext.user = values
        authContext.loading = false
      } else {
        authContext.token = values.token
        authContext.isLoggedIn = true;
        authContext.user = values.data
        authContext.role = values.roleName
        authContext.permissions = values.permissions
        authContext.loading = false
      }
      return true;
    }
    return false;
  }

  const updateAuthContext = (authValues: any, updateToken: boolean): any => {
    return new Promise(function (resolve, reject) {
      if (setValues(authValues, updateToken)) {
        resolve({
          operation: CONSTANTS.AUTH_PROMISE_SUCCESSFUL
        })
      } else {
        reject({
          operation: CONSTANTS.AUTH_PROMISE_FAILED
        })
      }
    });
  }

  return {
    updateAuthContext
  }
};

export default useHandleAuthValues;
