import { ReactElement, memo } from 'react';
import { Text, HStack, Image, Stack, Spacer, Heading, Card, CardBody, Flex, Box, Divider, Badge } from '@chakra-ui/react'
import { MonkeyIco } from 'assets';
import { Currency } from 'components/generic';
import { getWithDefault } from 'utils';
import { SettingsIcon } from '@chakra-ui/icons';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';
import { TO } from 'constants/_routes';

const StrategyCard: any = (props: any): ReactElement => {

  const {
    subscription,
    children
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  /**
   * to handle the editing of already subscribed strategy or configuring a new strategy
   * for subscribed strategy subscriptionSlug is used to fetch the saved/configured data of the strategy
   * @param slug 
   */
  const editSubscription = (slug: string) => {
    navigate(`${TO.EDIT_SUBSCRIPTION}/${slug}`, {
      state: {
        // subscriptionSlug: getWithDefault(subscription, 'strategy.subscriptionSlug', null),
        // TODO: passing complete subscription as we do not have api support to fetch strategy by id
        // once we have tha support we pass subscriptionSlug only to validate
        subscription: subscription,
        // to check the mode - create fresh strategy or editing existing subscribed strategy
        subscriptionSlug: getWithDefault(subscription, 'strategy.subscriptionSlug', null)
      }
    })
  };

  const isEditActionVisible = () => {
    const routeMatched: any = matchRoutes([{
      path: `${TO.EDIT_SUBSCRIPTION}/:slug`
    }
    ], location)
    return !routeMatched ? true : false;
  };

  return (
    <>
      <Card
        borderTop={3}
        borderTopStyle='solid'
        borderTopColor='brand.500'
        boxShadow='md'>
        <CardBody p={0}>
          <HStack p={2} spacing='3' bg='gray.100'>
            <Flex alignItems='center' minW='16' minH='16' h='16' w='16' bgColor='brand.300' borderRadius='6' shadow='md'>
              <Image
                p={2}
                src={MonkeyIco}
                alt='strategy'
              />
            </Flex>
            <Stack w='100%'>
              <HStack>
                <Heading size='md'>{getWithDefault(subscription, 'strategy.name', '--')}</Heading>
                <Spacer />
                {isEditActionVisible() &&
                  <SettingsIcon fontSize='2xl'
                    onClick={() => editSubscription(subscription.strategy.slug)} />}
                <Badge colorScheme='brand'>{getWithDefault(subscription, 'status', '')}</Badge>
              </HStack>
              <Text fontSize='md' color='grey'>by {getWithDefault(subscription, 'strategy.createdByUser', '')}</Text>
            </Stack>
          </HStack>
          <Divider />
          <Stack mb={2}>
            <Stack
              p={2}
              bg='gray.50'>
              <Text fontSize='md' color='grey' textAlign='justify'>
                {getWithDefault(subscription, 'strategy.description', '')}
              </Text>
            </Stack>
            <HStack
              p={2}
              alignItems='flex-start'>
              <Flex
                flexDirection='column'>
                <Text fontSize='md' color='theme.black' fontWeight='medium'>Min. Capital Required</Text>
                <Currency color='theme.black' fontSize='xl' fontWeight='semibold'>{getWithDefault(subscription, 'strategy.minCapitalNeeded', 0)}</Currency>
              </Flex>
              <Spacer />
              <Flex
                flexDirection='column'>
                <Text fontSize='md' color='theme.black' fontWeight='medium'>Monthly cost</Text>
                <Currency fontSize='xl' fontWeight='bold'>{getWithDefault(subscription, 'strategy.monthlyCost', 0)}</Currency>
                <Text as='i' fontSize='sm' color='theme.black'>{getWithDefault(subscription, 'strategy.monthlyCostDescription', 0)}</Text>
              </Flex>
            </HStack>
          </Stack>
        </CardBody>
      </Card>
      {children}
    </>
  );
};

export default memo(StrategyCard);
