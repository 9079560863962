import { ComponentStyleConfig } from '@chakra-ui/react'

// style object for base or default style
const baseStyle = () => {
  return {
    borderRadius: "6px",
    fontWeight: "semibold",
    lineHeight: "150%",
    _focus: {
      boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
    },
  };
};

// styles for different sizes ("sm", "md", "lg")
const sizes = {
  lg: {
    fontSize: "lg",
    px: "24px",
    py: "12px",
  },
  md: {
    fontSize: "md",
    px: "16px",
  },
  sm: {
    fontSize: "sm",
    borderRadius: "4px",
    px: "12px",
    py: "7px",
  },
  xs: {
    fontSize: "xs",
    borderRadius: "4px",
    px: "8px",
    py: "5px",
  },
};

const disabledStyle = {
  backgroundColor: "gray.100",
  borderColor: "gray.100",
  color: "gray.500",
};

// styles for different visual variants ("outline", "solid")
const variants = {
  round: {
    borderRadius: '3xl',
    backgroundColor: 'brand.400',
    boxShadow: '0 0 2px 2px #efdfde',
    color: '#fff',
    _hover: {
      _disabled: {
        ...disabledStyle
      }
    }
  }
};

// default values for 'size', 'variant' and 'colorScheme'
const defaultProps = {
  variant: "solid",
  size: "md",
  colorScheme: "brand",
};

const Button: ComponentStyleConfig = {
  baseStyle,
  sizes,
  variants,
  defaultProps,
};

export default Button;
