import { Flex, HStack, Heading, IconButton, Spacer, Image, useDisclosure } from '@chakra-ui/react';
import { useEffect, useRef, useState } from "react";
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom'
import { ArrowBackIcon } from '@chakra-ui/icons';
import { ConfirmationDialog } from 'components/dialogs';
import { MonkeyIco } from './../../assets';
import { HiLogout } from 'react-icons/hi';
import { TO, P_DASHBOARD, P_SUBSCRIBE, P_ONBOARDING, P_EDIT_SUBSCRIPTION } from './../../constants';
import useLogoutService from 'hooks/useLogoutService';

const HIDE_ON_ROUTES = ['Dashboard'];

const TITLE_MAP = [
  { path: TO.ROOT, title: P_DASHBOARD.HEADING },
  { path: TO.ONBOARDING, title: P_ONBOARDING.HEADING },
  { path: TO.SUBSCRIBE_STRATEGY, title: P_SUBSCRIBE.HEADING },
  { path: `${TO.EDIT_SUBSCRIPTION}/:slug`, title: P_EDIT_SUBSCRIPTION.HEADING },
];

const Header = () => {

  let navigate = useNavigate();
  let curLoc = useLocation();

  const { logout } = useLogoutService();

  const { isOpen, onOpen, onClose }: any = useDisclosure();
  const dialogRef: any = useRef<HTMLButtonElement>();

  const [pageTitle, setPageTitle] = useState('');
  const [dialogProps, setDialogProps] = useState<any>({});

  useEffect(() => {
    const [{ route }]: any = matchRoutes(TITLE_MAP, curLoc)
    if (route) {
      setPageTitle(route.title)
    }
  }, [curLoc])

  const hideWhenRoute = () => {
    if (!HIDE_ON_ROUTES.includes(pageTitle)) {
      return true;
    }
  };

  const logoutAction = () => {
    setDialogProps({
      title: `Logout?`,
      description: 'Are you sure, you want to log out?',
      actions: {
        submit: 'Logout'
      },
      confirmationCallback: () => logout()
    });
    onOpen();
  }

  return (
    <>
      <Flex
        as="header"
        position="fixed"
        backgroundColor="rgba(255, 255, 255, 0.8)"
        backdropFilter="saturate(180%) blur(5px)"
        borderBottom='1px solid #e2e8f0'
        h='60px'
        w={{
          base: '100%',
          xl: '1200px',
          '2xl': '1536px'
        }}
        maxW={{
          base: '100%',
          xl: '1200px',
          '2xl': '1536px'
        }}
        zIndex='99'>
        <HStack 
          w='100%'
          m='2'>
          {hideWhenRoute() && <ArrowBackIcon onClick={() => navigate(TO.ROOT)} />}
          <Image
            h={8}
            src={MonkeyIco} />
          <Heading>{pageTitle}</Heading>
          <Spacer />
          <IconButton
            aria-label='logout'
            icon={<HiLogout />}
            variant='ghost'
            colorScheme='black'
            fontSize='20px'
            onClick={() => logoutAction()} />
        </HStack>
      </Flex>
      <ConfirmationDialog
        dialogRef={dialogRef}
        isDialogOpen={isOpen}
        onDialogClose={onClose}
        dialogProps={dialogProps} />
    </>
  )
}

export default Header;

// references:
// https://stackoverflow.com/questions/65705086/react-router-react-router-dom-setting-page-title-from-current-route-functiona