export const styles = {
  global: {
    "*": {
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      ":focus": {
        boxShadow: "none !important",
      },
      ":focus-visible": {
        borderColor: "revert !important",
        boxShadow: "none !important",
      }
    },
  },
};
