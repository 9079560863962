import { Button } from '@chakra-ui/react'

const AMButton = ({
  label,
  ...otherProps
}: any): any => {

  return (
    <Button
      {...otherProps}
    >
      {label}
    </Button>
  );
};

export default AMButton;
