import { useState } from 'react';
import { getWithDefault } from 'utils';
import { UserService } from 'services';
import { useToast } from '@chakra-ui/react';
import { MESSAGES, TOAST_STATUS } from '../constants';

const useDashboardDetailsService: any = (): any => {

  // lib hooks
  const toast = useToast();

  const [myDashboardDetailsRes, setMyDashboardDetailsRes] = useState<any>({
    response: null,
    error: false,
    loading: false
  });

  const myDashboardDetailsPromise = () => {
    return new Promise(function (resolve, reject) {
      setMyDashboardDetailsRes({ ...myDashboardDetailsRes, error: null, loading: true });
      UserService.getDashboardDetails()
        .then((response: any) => {
          const { status } = response;
          if (status) {
            resolve({
              response: getWithDefault(response, 'data', false),
              error: false,
              loading: false
            });
          }
        })
        .catch((error: any) => {
          const { response } = error;
          toast({
            description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
            status: TOAST_STATUS.ERROR
          })
          reject({
            error: response,
            loading: false
          });
        })
    })
  }

  return myDashboardDetailsPromise;
}

export default useDashboardDetailsService;