import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const customVariant = defineStyle(props => {
  const { colorScheme: c } = props;
  return {
    bg: `${c}.200`,
    _dark: {
      bg: `${c}.50`,
    },
  };
});

export const badgeTheme = defineStyleConfig({
  variants: {
    custom: customVariant,
  },
  defaultProps: {
    variant: "custom"
  },
});
