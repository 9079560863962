// import { Navigate, Outlet } from 'react-router-dom'
// import useAuth from "./../../context/providers/useAuth";

// export const PrivateRoute: React.FC<any> = () => {
//   const auth = useAuth();
//   return auth ? <Outlet /> : <Navigate to="/login" />;
// }

import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
// import useAuth from './../../context/providers/useAuth';
import AuthContext from '../../context/auth/auth-context';
import { TO } from './../../constants';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isLoggedIn = false, loading = false }: any = useContext(AuthContext);

  return isLoggedIn ? <Component /> : <Navigate replace to={TO.LOGIN} />
  // return (
  //   <Route
  //     {...rest}
  //     render={(props: any) =>
  //       isLoggedIn ? (
  //         <Component key={props.location.key} {...props} />
  //       ) : (
  //         <Navigate to="/login" />
  //       )
  //     }
  //   />
  // );
};

export default PrivateRoute;