const colors = {
  brand: {
    50: '#fff4db',
    100: '#fee2af',
    200: '#fbce81',
    300: '#f8bb51',
    400: '#f6a722',
    500: '#dd8e09',
    600: '#ac6e04',
    700: '#7c4f01',
    800: '#4b2e00',
    900: '#1e0f00',
  },
  accent: {
    500: 'rgba(255, 193, 0, 1)',
  },
  success: {
    500: 'rgba(56,161,105,1)',
  },
  error: {
    500: 'rgba(229,62,62,1)',
  },
  warning: {
    500: 'rgba(221,107,32,1)',
  },
  gray: {
    50: 'rgba(247, 250, 252, 1)',
    100: 'rgba(237, 242, 247, 1)',
    200: 'rgba(226, 232, 240, 1)',
    300: 'rgba(203, 213, 224, 1)',
    400: 'rgba(160, 174, 192, 1)',
    500: 'rgba(113, 128, 150, 1)',
    600: 'rgba(74, 85, 104, 1)',
    700: 'rgba(45, 55, 72, 1)',
    800: 'rgba(26, 32, 44, 1)',
    900: 'rgba(23, 25, 35, 1)',
  },
  black: {
    500: '#000000',
  },
  theme: {
    black: '#2D3748',
  },
  white: {
    500: 'rgba(255, 255, 255, 1)',
  },
  label: {
    500: 'rgb(160, 174, 192,1)',
  },
  badge: {
    1: 'rgba(113, 128, 150, 1)',
    2: 'rgba(56, 161, 105, 1)',
    3: 'rgba(229, 62, 62, 1)',
    4: 'rgba(221, 107, 32, 1)',
    5: 'rgba(128, 90, 213, 1)',
    6: 'rgba(49, 151, 149, 1)',
  },
  header: {
    primary: 'rgb(231, 246, 255,1)',
  },
  footer: {
    primary: 'rgba(231, 246, 255, 1)',
    secondary: 'rgba(255, 255, 255, 1)',
  },
  gold: {
    500: '#FFDD81',
    700: '#FFD15C',
  },
  silver: {
    500: '#CBD5E0',
    700: '#E2E8F0',
  },
  bronze: {
    500: '#FBD38D',
    700: '#F6AD55',
  },
  currency: '#2ecc71',
  notifiers: {
    default: '#edf2f7',
    default_text: '#1a202c',
    general: '#e6fffa',
    success_bg: '#c6f6d5',
    success_text: '#22543d',
    error: '',
    info: '#c4f1f9',
    info_text: '#086f83'
  },
};

export default colors;
