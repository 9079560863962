import { Text } from "@chakra-ui/react";

const Currency = (props: any) => {
  return (
    <Text {...props}>{props.children.toLocaleString('en-IN', {
      maximumFractionDigits: 0,
      style: 'currency',
      currency: 'INR'
    })}</Text>
  )
}

export default Currency;