import HTTP from "./http";
import {
  API_URL,
  URLS
} from './../constants';

const {
  ALGO
} = URLS;

const {
  PREFIX,
  BASE_URLS
} = ALGO;

const URL_PREFIX = PREFIX;

const {
  SQUARE_OFF,
} = BASE_URLS;

const AlgoService = {
  async squareOffAllPositions(strategySlug) {
    const url = `${API_URL}${URL_PREFIX}${SQUARE_OFF}?strategySlug=${strategySlug}`;
    const { data: response } = await HTTP.post(`${url}`);
    return response;
  },
}

export default AlgoService