import { FC, ReactElement, useContext, useEffect, useState } from 'react'
import { Box, Flex, Stack, Text, Image, Divider, useToast, HStack, Center, InputRightElement } from '@chakra-ui/react'
import { Link, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { useForm } from 'react-hook-form';
import AMButton from './../../components/generic/AMButton'
import AuthService from './../../services/auth'
import { AuthLayout } from './../../layouts';
import ThemeInput from './../../components/form/ThemeInput';
import AuthContext from '../../context/auth/auth-context';
import { useHandleAuthValues } from './../../context';
import AMLogo from './../../assets/images/logo.png';
import { CONSTANTS, USER, SIGNUP, P_SIGNUP, TO, P_GENERAL, MESSAGES, TOAST_STATUS } from "./../../constants/";
import { saveDataInStorage, getWithDefault } from '../../utils';
import {
	GeneralTypes
} from './../../types';
import { SignupValidationSchema } from 'utils/_validations';

interface IReferralDetails {
	referralCode: string | null;
	user: string | null;
	userSlug: string | null;
}

const Signup: FC = (): ReactElement => {
	const {
		register,
		getValues,
		setValue,
		handleSubmit,
		formState: { errors },
	}: any = useForm({
		resolver: yupResolver(SignupValidationSchema())
	});

	// lib hooks
	let navigate = useNavigate();
	const toast = useToast();

	// custom hooks
	const authContext: any = useContext(AuthContext);
	const useHandleAuthHook = useHandleAuthValues();

	let [searchParams, setSearchParams] = useSearchParams();
	const [isValidReferralCode, setIsValidReferralCodeFlag] = useState(false);
	const [referralDetails, setReferralDetails] = useState<IReferralDetails>({
		referralCode: null,
		user: null,
		userSlug: null
	});

	const submit = (values: any) => {
		const referredByUserSlug = referralDetails.userSlug
		const registrationValues = {
			...values,
			referredByUserSlug
		}

		delete registrationValues['referralCode'];

		AuthService.register(registrationValues).then((success: any) => {
			const { status } = success;
			if (status) {
				saveDataInStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY, success.data.token)
				useHandleAuthHook.updateAuthContext(getWithDefault(success, 'data', {}), true)
					.then((response: GeneralTypes.IAuthResponse) => {
						if (response.operation === CONSTANTS.AUTH_PROMISE_SUCCESSFUL) {
							navigate(TO.ROOT)
						}
					})
					.catch((error: string) => {
						console.error(MESSAGES.SOMETHING_BAD_HAPPENED, error)
					})
			}
		})
			.catch((error: any) => {
				const { response } = error;
				toast({
					description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
					status: TOAST_STATUS.ERROR
				})
			})
	}

	useEffect(() => {
		const referredBy = searchParams.get('referredBy');
		if (referredBy) {
			setValue('referralCode', referredBy)
			validateReferralCode();
		}
	}, [searchParams]);

	const validateReferralCode = () => {
		const referralCode = getValues("referralCode")
		AuthService.validateReferralCode(referralCode)
			.then((success: any) => {
				const { status, data } = success;
				if (status) {
					setIsValidReferralCodeFlag(true)
					setReferralDetails(data);
				}
			})
			.catch((error: any) => {
				const { response } = error;
				toast({
					description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
					status: TOAST_STATUS.ERROR
				})
			})
	}

	return (
		<AuthLayout>
			<Stack spacing="4">
				<Center>
					<Image
						h={{ base: '18px', sm: '30px' }}
						src={AMLogo} />
				</Center>
				<Divider />
				<Stack mb='4' spacing={{ base: '2', md: '3' }} textAlign="center">
					<Text
						fontSize={{ base: '3xl', sm: '4xl' }}
						fontWeight='semibold'>{P_SIGNUP.HEADING}</Text>
					<Text
						fontSize={{ base: 'md', md: 'lg' }}>{P_SIGNUP.SUB_HEADING}</Text>
				</Stack>
			</Stack>
			<Box as={"form"} onSubmit={handleSubmit(submit)}>
				<Stack spacing={6}>
					<HStack>
						<ThemeInput
							isRequired
							name={SIGNUP.REFERRAL_CODE.NAME}
							placeholder={SIGNUP.REFERRAL_CODE.PLACEHOLDER}
							type={SIGNUP.REFERRAL_CODE.TYPE}
							register={register}
							isDisabled={isValidReferralCode}
							error={errors[SIGNUP.REFERRAL_CODE.NAME]}
							subActionComponent={
								<InputRightElement width='4.5rem'>
									<AMButton
										label="Validate"
										variant='ghost'
										colorScheme="brand"
										onClick={validateReferralCode}
										isDisabled={isValidReferralCode}
									/>
								</InputRightElement>
							}
						/>
					</HStack>
					{isValidReferralCode && <Flex>
						<Text fontSize='sm'>{CONSTANTS.REFERRED_BY}</Text>
						<Text ml={1} fontSize='sm' color='brand.500'>{getWithDefault(referralDetails, 'user', '')}</Text>
					</Flex>}
					<ThemeInput
						isRequired
						name={USER.EMAIL.NAME}
						placeholder={USER.EMAIL.PLACEHOLDER}
						type={USER.EMAIL.TYPE}
						register={register}
						isDisabled={!isValidReferralCode}
						error={isValidReferralCode && errors[USER.EMAIL.NAME]}
					/>
					<ThemeInput
						isRequired
						name={USER.PHONE.NAME}
						placeholder={USER.PHONE.PLACEHOLDER}
						type={USER.PHONE.TYPE}
						register={register}
						isDisabled={!isValidReferralCode}
						error={isValidReferralCode && errors[USER.PHONE.NAME]}
					/>
					<ThemeInput
						isRequired
						name={USER.PASSWORD.NAME}
						placeholder={USER.PASSWORD.PLACEHOLDER}
						type={USER.PASSWORD.TYPE}
						register={register}
						isDisabled={!isValidReferralCode}
						error={isValidReferralCode && errors[USER.PASSWORD.NAME]}
					/>
				</Stack>
				<Box
					mt="4"
					display='flex'
					justifyContent='flex-end'>
					<AMButton
						rightIcon={<ArrowForwardIcon />}
						type="submit"
						label={P_GENERAL.SIGNUP}
						variant='round'
						colorScheme="brand"
						bgGradient='linear(to-r, brand.400, brand.500)' />
				</Box>
			</Box>

			<HStack
				mt='12'
				alignItems='center'
				justifyContent='center'>
				<Text
					fontSize='md'>{P_GENERAL.HAVE_ACCOUNT}</Text>
				<Text
					color='brand.500'
					fontSize='md'
					fontWeight='bold'>
					<Link to={TO.LOGIN}>{P_GENERAL.URL_LBL_LOGIN_HERE}</Link>
				</Text>
			</HStack>
		</AuthLayout>
	)
}

export default Signup;