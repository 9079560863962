const P_LOGIN = {
  HEADING: 'Login',
  SUB_HEADING: 'Welcome Back!'
}

const P_SIGNUP = {
  HEADING: 'Create account',
  SUB_HEADING: 'Let\'s get Started!'
}

const P_RESET_PASS = {
  HEADING: 'Forgot Password?',
  SUB_HEADING: 'No worries, Enter the email address associated with your account.'
}

const P_GENERAL = {
  LOGIN: 'Login',
  SIGNUP: 'Signup',
  RESET_PASSWORD: 'Reset password',
  FORGOT_PASSWORD: 'Forgot password?',
  NO_ACCOUNT: 'Don\'t have an account?',
  URL_LBL_REGISTER_HERE: 'Register here',
  HAVE_ACCOUNT: 'Already have an account?',
  URL_LBL_LOGIN_HERE: 'Sign in',
  NEED_HELP: 'Need help?',
  URL_LBL_DOWNLOAD_USER_MANUAL: 'Download user manual',
  CLICK_HERE: 'Click here'
}

const P_ONBOARDING = {
  HEADING: 'Choose Broker',
  SUB_HEADING: 'Choose desired broker and connect with the API key!'
}

const P_SUBSCRIBE = {
  HEADING: 'Subscribe Strategy',
  SUB_HEADING: 'Any fantastic tag line here!!',
}

const P_EDIT_SUBSCRIPTION = {
  HEADING: 'Edit Subscription',
}

const P_DASHBOARD = {
  HEADING: 'Dashboard',
  MY_ACTIONS: 'My actions',
}

export {
  P_LOGIN,
  P_SIGNUP,
  P_RESET_PASS,
  P_GENERAL,
  P_ONBOARDING,
  P_SUBSCRIBE,
  P_EDIT_SUBSCRIPTION,
  P_DASHBOARD
}