import { Flex, Spinner } from "@chakra-ui/react";

const Loader = (props: any) => {
  return (
    <Flex flexDirection='column' alignItems='center' justifyContent='center' h='100%'>
      <Spinner colorScheme='brand' size='xl' />
      <Flex mt='8'>
        {props.children}
      </Flex>
    </Flex>
  )
}

export default Loader;