import { useContext, useEffect, useState } from 'react';
import { Box, Text, Stack, Radio, RadioGroup, Divider, HStack, Center, useToast, Grid, Image, GridItem, Flex, Show } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { BrokerService } from './../../services'
import { P_ONBOARDING, ACTIONS, TO, P_GENERAL, MESSAGES, TOAST_STATUS, CONSTANTS } from './../../constants';
import { ThemeInput } from './../../components/form';
import { AMButton, Notifier } from './../../components/generic'
import { ArrowForwardIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { getWithDefault } from 'utils/_utils';
import { TestBrokerConnectionValidationSchema } from 'utils/_validations';
import AuthContext from 'context/auth/auth-context';
import { GeneralTypes } from 'types';
import { useHandleAuthValues } from 'context';
import { DummyAmIllus } from './../../assets';
import './_styles.scss';

const Onboarding = (): any => {

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  }: any = useForm<any>({
    resolver: yupResolver(TestBrokerConnectionValidationSchema())
  });

  // lib hooks
  const navigate = useNavigate();
  const toast = useToast();

  // custom hooks
  const authContext: any = useContext(AuthContext);
  const useHandleAuthHook = useHandleAuthValues();

  // local states
  const [brokers, setBrokers] = useState([]);
  const [brokersFetchReq, setBrokersFetchReq] = useState<boolean>(false);
  const [isTestConnectionReqLoading, setTestConnectionReqLoadingFlag] = useState<boolean>(false);
  const [brokerConnectionSuccess, setBrokerConnectionSuccess] = useState<boolean>(false);

  // to fetch brokers
  useEffect(() => {
    setBrokersFetchReq(true)
    BrokerService.getBrokers({
      page: 0,
      size: 10
    })
      .then((success: any) => {
        const { status } = success;
        if (status) {
          const setupConfig = getWithDefault(authContext, 'user.setupConfiguration', {});
          const brokerDetails = getWithDefault(authContext, 'user.brokerDetails', {});
          setBrokersFetchReq(false)
          setBrokers(success.data)
          if (setupConfig.isBrokerSaved) {
            setValue("apiKey", brokerDetails['ANGELONE'].apiKey)
            setValue("clientCode", brokerDetails['ANGELONE'].clientCode)
            setValue("pin", brokerDetails['ANGELONE'].pin)
            setValue("totpSecretKey", brokerDetails['ANGELONE'].totpSecretKey)
          }
        }
      })
      .catch((error: any) => {
        const { response } = error;
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR
        })
        setBrokersFetchReq(false)
      })
  }, []);

  const onSubmit = (values: any): any => {
    setTestConnectionReqLoadingFlag(true);
    BrokerService.upsertToUser(values)
      .then((success: any) => success.status && success)
      .then((brokerDetails: any) => {
        useHandleAuthHook.updateAuthContext(getWithDefault(brokerDetails, 'data', {}), false)
          .then((response: GeneralTypes.IAuthResponse) => {
            if (response.operation === CONSTANTS.AUTH_PROMISE_SUCCESSFUL) {
              const brokerSlug = Object.keys(getWithDefault(brokerDetails, 'data.brokerDetails', {}))[0];
              BrokerService.testBrokerConnection(brokerSlug)
                .then((connectionSuccess: any) => {
                  useHandleAuthHook.updateAuthContext(getWithDefault(connectionSuccess, 'data', {}), false)
                    .then((response: GeneralTypes.IAuthResponse) => {
                      if (response.operation === CONSTANTS.AUTH_PROMISE_SUCCESSFUL) {
                        setTestConnectionReqLoadingFlag(false);
                        navigate(TO.ROOT)
                      }
                    })
                    .catch((error: string) => {
                      setTestConnectionReqLoadingFlag(false);
                      console.error(MESSAGES.SOMETHING_BAD_HAPPENED, error)
                    })
                })
                .catch((connectionError: any) => {
                  setTestConnectionReqLoadingFlag(false);
                  toast({
                    description: getWithDefault(connectionError, 'response.data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
                    status: TOAST_STATUS.ERROR
                  })
                })
            }
          })
          .catch((error: string) => {
            console.error(MESSAGES.SOMETHING_BAD_HAPPENED, error)
          })
      })
      .catch((error: any) => {
        const { response } = error;
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR
        })
      })
  }

  const renderBrokers = () => {
    const brokersRes = getWithDefault(brokers, 'content', []);
    if (brokersRes) {
      return (
        <>
          {brokersRes.map((broker: any, brokerIDx: number) => {
            const { slug, name, logoImageUrl } = broker;
            return (
              <Box key={brokerIDx} minH='90px' pos='relative' textAlign='center'>
                <Radio className='img-radio' value={slug}>
                  <CheckCircleIcon
                    boxSize={6}
                    pos='absolute'
                    color='brand.500'
                    bgColor='#fff'
                    top={-2}
                    right={-3}
                    m={1} />
                  <img height="80px" width="80px" src={logoImageUrl} alt={name} style={{ 'padding': '8px' }} />
                </Radio>
                <Box>
                  <Text fontSize='lg' fontWeight='bold'>{name}</Text>
                </Box>
              </Box>
            )
          })}
        </>
      )
    }
  };

  const renderFieldsByBroker = () => {
    const brokerFields = getWithDefault(brokers, 'content.0.integrationFields', []);
    if (brokerFields) {
      return (
        <>
          {brokerFields.map((field: any) => {
            const { key, displayName } = field;
            return (
              <ThemeInput
                key={key}
                isRequired
                name={key}
                placeholder={displayName}
                type='text'
                register={register}
                error={errors[key]}
              />
            )
          })}
        </>
      )
    }
    return 'No integration fields found!'
  };

  return (
    <HStack w='100%' h='100%'>
      <Grid
        h='100%'
        w='100%'
        gap={{
          base: 4,
          lg: 6
        }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(2, 1fr)',
          '2xl': 'auto minmax(calc(100% - 700px), 60%)'
        }}>
        <Show above='md'>
          <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
            <Flex bgGradient='linear(to-r, brand.400, brand.500)' h='100%' alignItems='center' justifyContent='center'>
              <Image
                h={{ base: '175px', md: '400px', lg: '450px' }}
                src={DummyAmIllus} />
            </Flex>
          </GridItem>
        </Show>
        <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
          <Stack m={2}>
            <Stack spacing="4">
              <Stack mb='4' spacing={{ base: '2', md: '3' }} textAlign="center">
                <Text
                  fontSize={{ base: 'md', md: 'lg' }}>{P_ONBOARDING.SUB_HEADING}</Text>
              </Stack>
            </Stack>
            <Box as={"form"} onSubmit={handleSubmit(onSubmit)} noValidate>
              <Stack spacing={3}>
                {brokersFetchReq ?
                  <Center>
                    <Text fontSize='sm'>Fetching available brokers ... Please wait...</Text>
                  </Center> : <Center>
                    <Controller
                      name="brokerSlug"
                      control={control}
                      defaultValue='ANGELONE'
                      render={({ field: { onChange, ...props } }) => {
                        return (
                          <RadioGroup
                            onChange={(value: string) => onChange(value)}
                            className='img-radio-parent'
                            {...props}>
                            <HStack>
                              {renderBrokers()}
                            </HStack>
                          </RadioGroup>
                        )
                      }
                      } />
                  </Center>}
                {renderFieldsByBroker()}
              </Stack>
              {isTestConnectionReqLoading && <Box my={4}>
                <Notifier
                  type='info'
                  text='Please wait as the test connection request might take few minutes to validate!' />
              </Box>}
              <HStack
                justifyContent='flex-end'>
                <Box
                  mt="4"
                  display='flex'
                  justifyContent='flex-end'>
                  <AMButton
                    rightIcon={<ArrowForwardIcon />}
                    type="submit"
                    isLoading={isTestConnectionReqLoading}
                    loadingText='Please wait ...'
                    label={ACTIONS.TEST_CONNECTION}
                    variant='round'
                    colorScheme="brand"
                    bgGradient='linear(to-r, brand.400, brand.500)'
                  />
                </Box>
              </HStack>
            </Box>
            <HStack
              mt='6'
              alignItems='center'
              justifyContent='center'>
              <Text
                fontSize='md'>{P_GENERAL.NEED_HELP}</Text>
              <Text
                as='a'
                href={require("./../../assets/guides/broker-user-manual.pdf")}
                download="broker-user-manual.pdf"
                color='brand.500'
                fontSize='md'
                fontWeight='bold'>
                {P_GENERAL.URL_LBL_DOWNLOAD_USER_MANUAL}
              </Text>
            </HStack>
          </Stack>
        </GridItem>
      </Grid>
    </HStack>
  );
};

export default Onboarding;
