import { Box, Text } from "@chakra-ui/react";

const Notifier = (props: any) => {

  const {
    type = 'default',
    text,
    wrapperProps,
    textProps
  } = props;

  return (
    <Box p={2} borderColor='gray.50' borderRadius='md' bg={`notifiers.${type}`} {...wrapperProps}>
      <Text color={`notifiers.${type}_text`} fontSize='md' {...textProps}>{text}</Text>
    </Box>
  )
}

export default Notifier;