const typography = {
    fonts: {
      heading: 'Space Grotesk',
      body: 'Space Grotesk',
      mono: 'Space Grotesk',
    },
  
    fontSizes: {
      xs: "0.563rem", //9px
      sm: "0.625rem", //10px
      md: "0.75rem", //12px
      lg: "0.875rem", //14px
      xl: "1rem", //16px
      "2xl": "1.125rem", //18px
      "3xl": "1.375rem", //22px
      "4xl": "2.125rem", //34px
      "5xl": "2.625rem", //42px
      "6xl": "3.125rem", //50px
    },
  
    fontWeights: {
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 900,
    },
  };
  
  export default typography;
  