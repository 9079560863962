export const CONSTANTS = {
  AUTH: {
    AUTH_TOKEN_KEY: "X-AUTH-TOKEN",
    CURRENT_LOCALE: "X-LANG-TAG",
    DEFAULT_LOCALE: "en",
    ACCEPT_LANGUAGE: "Accept-Language",
    USER_SLUG: "USER_SLUG",
    USER: "USER",
    USER_KEY: "user",
    LANG_CODE: "langCode",
    TOKEN: "_token",
    CONFIG: "CONFIG",
    PERMISSION: "PERMISSION",
    ROLE: "ROLE",
  },
  LOGIN: 'LOGIN',
  LOADING: 'Loading...',
  AUTH_PROMISE_SUCCESSFUL: 'SUCCESSFUL',
  AUTH_PROMISE_FAILED: 'FAILED',
  REFERRED_BY: 'Referred by',
  TERMS_AND_CONDITIONS: 'Terms and Conditions',
  COMPANY: '© SIX SPRINTS TECH CONSULTANTS PVT LTD. ALL RIGHTS RESERVED.',
  MAIL: 'support@algomonkey.in',
  BROKER_SAVED: 'Broker Saved',
  BROKER_CONNECTION_TESTED: 'Broker Connection Tested',
  STRATEGY_SUBSCRIBED: 'Strategy Subscribed'
}

export const MESSAGES = {
  SOMETHING_BAD_HAPPENED: 'Something bad happened!',
  OTP_SENT: 'OTP sent successfully, Kindly check your email Inbox and Spam folder!',
  MAX_OTP_ATTEMPS: 'Maximum attempts limit reached, please try after some!',
  NOT_RECEIVED_CODE: 'Still didn\'t received the code?',
  RESEND_OTP: 'Resend OTP in',
  PASSWORD_REQ_SUCCESS: 'Password has been reset successfully',
  ERROR_OCCURED: 'An error occurred',
  VALIDATING_TOKEN: 'Fetching user details',
}

export const TOAST_STATUS: Record<any, any> = {
  SUCCESS: 'success',
  ERROR: 'error'
}