import { FC, ReactElement, useCallback, useEffect } from 'react';
import { Box, Image, HStack, Grid, GridItem, Stack, Text, Flex, Spacer, Show } from '@chakra-ui/react'
import { useLocation, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import useEditSubscriptionService from 'hooks/useEditSubscriptionService';
import { getWithDefault } from 'utils';
import { AMButton, Currency } from 'components/generic';
import { AMSlider } from 'components/form';
import { ArrowForwardIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { ACTIONS, SLIDER_PROPS } from './../../constants';
import useUserService from 'hooks/useUserService';
import StrategyCard from 'pages/strategy/strategy-card';
import { DummyAmIllus } from './../../assets';

const EditSubscription: FC = (props: any): ReactElement => {

  const {
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm<any>({});

  // libs
  const params = useParams();
  const location = useLocation();

  // custom hooks
  // if !subscriptionSlug => create mode
  const { subscription = {}, editSubscriptionReq, editSubscriptionRes }: any = useEditSubscriptionService({
    slug: params.slug,
    subscriptionSlug: location.state?.subscriptionSlug,
    subscription: location.state?.subscription
  });
  const { userRes, brokerDetails }: any = useUserService();

  useEffect(() => {
    const createMode = !getWithDefault(location, 'state.subscriptionSlug', false);

    if (createMode) {
      const strategy = getWithDefault(location, 'state.subscription.strategy', false);

      if (strategy) {
        setValue('strategyParams.capitalUsagePercent', strategy.recommendedParams.capitalUsagePercent)
        setValue('strategyParams.riskPerTradePercent', strategy.recommendedParams.riskPerTradePercent)
        setValue('strategyParams.lossThresholdPercent', strategy.recommendedParams.lossThresholdPercent)
      }
    } else {
      if (subscription) {
        setValue('strategyParams.capitalUsagePercent', subscription.strategyParams.capitalUsagePercent)
        setValue('strategyParams.riskPerTradePercent', subscription.strategyParams.riskPerTradePercent)
        setValue('strategyParams.lossThresholdPercent', subscription.strategyParams.lossThresholdPercent)
      }
    }
  }, [subscription]);

  const getSubscriptionDetails = useCallback(() => {
    const createMode = !getWithDefault(location, 'state.subscriptionSlug', false);
    const subscriptionFromProps = getWithDefault(location, 'state.subscription', false)
    return createMode ? subscriptionFromProps : subscription
  }, [subscription]);

  const onSubmit = (values: any): any => {
    const {
      strategyParams
    } = values;
    const strategySlug = getWithDefault(getSubscriptionDetails(), 'strategy.slug', null);
    const brokerSlug = getWithDefault(userRes, 'response.selectedBroker', null);

    if (!strategySlug) {
      alert('Strategy slug not found!')
    } else if (!brokerSlug) {
      alert('Broker slug not found!')
    } else {
      const upsertSubscribeToStrategyPayload = {
        strategySlug,
        brokerSlug,
        strategyParams
      }
      editSubscriptionReq(upsertSubscribeToStrategyPayload)
    }
  }

  const getStrategyParams = () => {
    return getWithDefault(subscription, `strategyParams`, false)
  };

  const calcCapitalUsage = () => {
    const availableCapitalFromBroker = getWithDefault(brokerDetails, `ANGELONE.capital`, 0);
    const capitalUsage = watch("strategyParams.capitalUsagePercent")
      ? watch("strategyParams.capitalUsagePercent")
      : getWithDefault(getStrategyParams(), 'capitalUsagePercent', 0);
    return availableCapitalFromBroker * capitalUsage / 100;
  }

  const calcRiskPerTrade = () => {
    const capitalUsage = calcCapitalUsage();
    const riskPerTrade = watch("strategyParams.riskPerTradePercent")
      ? watch("strategyParams.riskPerTradePercent")
      : getWithDefault(getStrategyParams(), 'riskPerTradePercent', 0);
    return capitalUsage * riskPerTrade / 100
  }

  const calcCapitalThreshold = () => {
    const capitalUsage = calcCapitalUsage();
    const capitalThreshold = watch("strategyParams.lossThresholdPercent")
      ? watch("strategyParams.lossThresholdPercent")
      : getWithDefault(getStrategyParams(), 'lossThresholdPercent', 0);
    return capitalUsage * capitalThreshold / 100
  }

  return (
    <HStack w='100%' h='100%'>
      <Grid
        h='100%'
        w='100%'
        gap={{
          base: 4,
          lg: 6
        }}
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
          xl: 'repeat(2, 1fr)',
          '2xl': 'auto minmax(calc(100% - 700px), 60%)'
        }}>
        <Show above='md'>
          <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
            <Flex bgGradient='linear(to-r, brand.400, brand.500)' h='100%' alignItems='center' justifyContent='center'>
              <Image
                h={{ base: '175px', md: '400px', lg: '450px' }}
                src={DummyAmIllus} />
            </Flex>
          </GridItem>
        </Show>
        <GridItem colSpan={{ base: 1, md: 1, lg: 1 }}>
          <Box as={"form"} m={2} onSubmit={handleSubmit(onSubmit)} noValidate>
            <StrategyCard subscription={getSubscriptionDetails()}>
              <Flex
                flexDir='column'
                mt={6}
                mb={6}
                bg='gray.50'
                shadow='md'>
                <Flex p={2}>
                  <Flex
                    flexDir='column'>
                    <Text fontSize='md' color='theme.black' fontWeight='medium'>Available capital</Text>
                    <Currency fontWeight='bold' fontSize='2xl' color='currency'>
                      {getWithDefault(userRes, `response.brokerDetails.ANGELONE.capital`, 0)}
                    </Currency>
                  </Flex>
                  <Spacer />
                  <AMButton
                    label='Add Money'
                    variant='solid'
                    size='sm'
                    isDisabled={true} />
                </Flex>
                <Box
                  px={2}
                  py={1}
                  bg='gray.100'>
                  <Text fontSize='sm'>Add funds to your broker account for hassle-free investing.</Text>
                </Box>
              </Flex>
              <Box p={1} mb={6} borderColor='gray.50' borderRadius='md' bg='notifiers.general'>
                <Text color='notifiers.success_text' fontSize='md'>Please configure the trade parameters below to control the capital to be used and the risk to take for trading with this strategy.</Text>
              </Box>
              <Stack gap={0}>
                <Stack bg='gray.50' shadow='md' borderRadius='md' >
                  <Flex p={2}>
                    <Flex
                      flexDir='column'>
                      <Text fontSize='md' color='theme.black' fontWeight='medium'>Capital Usage</Text>
                      <Currency fontWeight='bold' fontSize='lg'>{calcCapitalUsage()}</Currency>
                    </Flex>
                    <Spacer />
                    <Text>
                      {watch("strategyParams.capitalUsagePercent")}%
                    </Text>
                  </Flex>
                  <Flex flexDir='column'>
                    <Controller
                      name="strategyParams.capitalUsagePercent"
                      // defaultValue={getWithDefault(subscription, 'strategyParams.capitalUsagePercent', 0)}
                      control={control}
                      render={({ field: { onChange, ...props } }) => {
                        return (
                          <AMSlider
                            id={SLIDER_PROPS.CAPITAL_USAGE.ID}
                            // defaultValue={getWithDefault(subscription, 'strategyParams.capitalUsagePercent', 0)}
                            min={SLIDER_PROPS.CAPITAL_USAGE.MIN}
                            max={SLIDER_PROPS.CAPITAL_USAGE.MAX}
                            step={SLIDER_PROPS.CAPITAL_USAGE.STEP}
                            onChange={(value: string) => onChange(value)}
                            updatedSliderValue={
                              watch("strategyParams.capitalUsagePercent")
                                ? watch("strategyParams.capitalUsagePercent")
                                : getWithDefault(subscription, 'strategyParams.capitalUsagePercent', 0)
                            }
                            sliderMarks={SLIDER_PROPS.CAPITAL_USAGE.SLIDER_MARKS}
                            {...props}
                          />
                        )
                      }
                      } />
                  </Flex>
                  <Flex
                    flexDir='row'
                    align='center'
                    mt={6}
                    px={2}
                    py={1}
                    bg='gray.100'>
                    <InfoOutlineIcon color='gray.500' fontSize='md' />
                    <Text ml={1} fontSize='sm'>Percentage of avaialable capital to invest in this strategy.</Text>
                  </Flex>
                </Stack>

                <Stack bg='gray.50' shadow='md' borderRadius='md'>
                  <Flex p={2}>
                    <Flex
                      flexDir='column'>
                      <Text fontSize='md' color='theme.black' fontWeight='medium'>Risk per trade (Stop loss)</Text>
                      <Currency fontWeight='bold' fontSize='lg'>{calcRiskPerTrade()}</Currency>
                    </Flex>
                    <Spacer />
                    <Text>
                      {watch("strategyParams.riskPerTradePercent")}%
                    </Text>
                  </Flex>
                  <Flex flexDir='column'>
                    <Controller
                      name="strategyParams.riskPerTradePercent"
                      control={control}
                      render={({ field: { onChange, ...props } }) => {
                        return (
                          <AMSlider
                            id={SLIDER_PROPS.RISK_PER_TRADE.ID}
                            min={SLIDER_PROPS.RISK_PER_TRADE.MIN}
                            max={SLIDER_PROPS.RISK_PER_TRADE.MAX}
                            step={SLIDER_PROPS.RISK_PER_TRADE.STEP}
                            onChange={(value: string) => onChange(value)}
                            updatedSliderValue={
                              watch("strategyParams.riskPerTradePercent")
                                ? watch("strategyParams.riskPerTradePercent")
                                : getWithDefault(subscription, 'strategyParams.riskPerTradePercent', 0)
                            }
                            sliderMarks={SLIDER_PROPS.RISK_PER_TRADE.SLIDER_MARKS}
                            {...props}
                          />
                        )
                      }
                      } />
                  </Flex>
                  <Flex
                    flexDir='row'
                    align='center'
                    mt={6}
                    px={2}
                    py={1}
                    bg='gray.100'>
                    <InfoOutlineIcon color='gray.500' fontSize='md' />
                    <Text ml={1} fontSize='sm'>Algo will automatically stop for the day if at anytime the loss goes beyond this value.</Text>
                  </Flex>
                </Stack>


                <Stack bg='gray.50' shadow='md' borderRadius='md'>
                  <Flex p={2}>
                    <Flex
                      flexDir='column'>
                      <Text fontSize='md' color='theme.black' fontWeight='medium'>Loss Threshold</Text>
                      <Currency fontWeight='bold' fontSize='lg'>{calcCapitalThreshold()}</Currency>
                    </Flex>
                    <Spacer />
                    <Text>
                      {watch("strategyParams.lossThresholdPercent")}%
                    </Text>
                  </Flex>
                  <Flex flexDir='column'>
                    <Controller
                      name="strategyParams.lossThresholdPercent"
                      control={control}
                      render={({ field: { onChange, ...props } }) => {
                        return (
                          <AMSlider
                            id={SLIDER_PROPS.LOSS_THRESHOLD.ID}
                            min={SLIDER_PROPS.LOSS_THRESHOLD.MIN}
                            max={SLIDER_PROPS.LOSS_THRESHOLD.MAX}
                            step={SLIDER_PROPS.LOSS_THRESHOLD.STEP}
                            onChange={(value: string) => onChange(value)}
                            updatedSliderValue={
                              watch("strategyParams.lossThresholdPercent")
                                ? watch("strategyParams.lossThresholdPercent")
                                : getWithDefault(subscription, 'strategyParams.riskPerTradePercent', 0)
                            }
                            sliderMarks={SLIDER_PROPS.LOSS_THRESHOLD.SLIDER_MARKS}
                            {...props}
                          />
                        )
                      }
                      } />
                  </Flex>
                  <Flex
                    flexDir='row'
                    align='center'
                    mt={6}
                    px={2}
                    py={1}
                    bg='gray.100'>
                    <InfoOutlineIcon color='gray.500' fontSize='md' />
                    <Text ml={1} fontSize='sm'>Stop further trading if the overall loss goes beyond this value.</Text>
                  </Flex>
                </Stack>
              </Stack>
            </StrategyCard>


            <Box
              mt='6'
              pb='6'
              display='flex'
              justifyContent='flex-end'>
              <AMButton
                rightIcon={<ArrowForwardIcon />}
                type="submit"
                label={ACTIONS.SUBSCRIBE}
                variant='solid'
                colorScheme="brand"
                bgGradient='linear(to-r, brand.400, brand.500)'
                isLoading={editSubscriptionRes?.loading}
                loadingText='Hold your horses!'
              />
            </Box>
          </Box>
        </GridItem>
      </Grid>
    </HStack>

  );
};
export default EditSubscription;
