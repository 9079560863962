import HTTP from "./http";
import {
  API_URL,
  URLS
} from './../constants';

const {
  STRATEGY
} = URLS;

const {
  PREFIX,
  BASE_URLS
} = STRATEGY;

const URL_PREFIX = PREFIX;

const {
  SEARCH,
} = BASE_URLS;

const StrategyService = {
  async getStrategies({ page = 0, size = 10 }) {
    const url = `${API_URL}${URL_PREFIX}${SEARCH}`;
    const { data: response } = await HTTP.post(`${url}`, {
      page,
      size
    });
    return response;
  },
}

export default StrategyService