import { useContext, useEffect, useState } from 'react';
import AuthContext from 'context/auth/auth-context';
import { getWithDefault } from 'utils';

const useUserService: any = (): any => {

  // custom hooks
  const authContext: any = useContext(AuthContext);

  const [user, setUser] = useState<any>({
    response: null,
    error: false,
    loading: false,
    requestCompleted: false,
  });

  useEffect(() => {
    if (!user.requestCompleted) {
      setUser({ ...user, error: null, loading: true });
      if (getWithDefault(authContext, 'user', false)) {
        setUser({
          response: getWithDefault(authContext, 'user', false),
          error: false,
          loading: false,
          requestCompleted: true
        });
      }
    }
  }, []);

  return {
    user: getWithDefault(user, 'response', false),
    userRes: user,
    isUserLoaded: user.requestCompleted,
    brokerDetails: getWithDefault(user, 'response.brokerDetails', false),
    setupConfiguration: getWithDefault(user, 'response.setupConfiguration', false),
  };
}

export default useUserService;