import HTTP from "./http";
import {
  API_URL,
  URLS
} from './../constants';

const {
  USER
} = URLS;

const {
  PREFIX,
  BASE_URLS
} = USER;

const URL_PREFIX = PREFIX;

const {
  DASHBOARD_DETAILS,
} = BASE_URLS;

const UserService = {
  async getDashboardDetails() {
    const url = `${API_URL}${URL_PREFIX}${DASHBOARD_DETAILS}`;
    const { data: response } = await HTTP.get(`${url}`);
    return response;
  },
}

export default UserService