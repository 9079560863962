import HTTP from "./http";

const API_URL = process.env.REACT_APP_NODE_ENV
const URL_PREFIX = 'auth/';
const BASE_URLS = {
	VALIDATE_REFERRAL_CODE: 'user/validate-referral-code',
	VALIDATE_TOKEN: 'user/validate-token',
	REGISTER: 'user/register',
	SIGNIN: 'user/login',
	SEND_OTP: 'user/send-otp',
	RESET_PASSWORD: 'user/reset',
	LOGOUT: 'user/logout',
	DELETE_USER: 'user/delete'
}

const {
	VALIDATE_REFERRAL_CODE,
	VALIDATE_TOKEN,
	REGISTER,
	SIGNIN,
	SEND_OTP,
	RESET_PASSWORD,
	LOGOUT,
	DELETE_USER
} = BASE_URLS;

const AuthService = {
	async validateReferralCode(referralCode) {
		const url = `${API_URL}${VALIDATE_REFERRAL_CODE}`;
		const { data: response } = await HTTP.post(`${url}?referralCode=${referralCode}`);
		return response;
	},

	async validateToken() {
		const url = `${API_URL}${URL_PREFIX}${VALIDATE_TOKEN}`;
		const { data: response } = await HTTP.post(`${url}`);
		return response;
	},

	async register(data) {
		const url = `${API_URL}${URL_PREFIX}${REGISTER}`;
		const { data: response } = await HTTP.post(`${url}`, data);
		return response;
	},

	async signin(data) {
		const url = `${URL_PREFIX}${SIGNIN}`;
		const { data: response } = await HTTP.post(`${url}`, data);
		return response;
	},

	async sendOtp(medium) {
		const url = `${URL_PREFIX}${SEND_OTP}`;
		const { data: response } = await HTTP.post(`${url}?authId=${medium}`);
		return response;
	},

	async resetPassword(data) {
		const url = `${URL_PREFIX}${RESET_PASSWORD}`;
		const { data: response } = await HTTP.post(`${url}`, data);
		return response;
	},

	async logout() {
		const url = `${URL_PREFIX}${LOGOUT}`;
		const { data: response } = await HTTP.post(`${url}`);
		return response;
	},

	// TODO: for testing purpose only - delete this capability afterwards
	async deleteUser(data) {
		const url = `${API_URL}${DELETE_USER}`;
		const { data: response } = await HTTP.post(`${url}`, data);
		return response;
	}
}

export default AuthService