import { useState } from "react";
import {
  Flex,
  Text,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { VALIDATION_MESSAGES } from "./../../constants";
import { getWithDefault } from "utils";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const ThemeInput: any = ({
  placeholder,
  name,
  label,
  label_suffix,
  isDisabled = false,
  type,
  defaultValue,
  isPasswordInput = false,
  isRequired,
  subActionComponent,
  error,
  customIcon,
  register = () => { },
  ...rest
}: any): any => {

  const [showPassword, setShowPassword] = useState(false)
  const onClickReveal = () => setShowPassword(!showPassword)

  const renderValidationError = () => {
    return (
      <Flex alignItems='center' mt={1}>
        <InfoOutlineIcon fontSize='sm' color='grey' />
        <Text ml={1} fontSize='sm' color='#ff4c30'>
          {getWithDefault(VALIDATION_MESSAGES, error.message, '')}
        </Text>
      </Flex>
    )
  }

  return (
    <FormControl variant="floating" isDisabled={isDisabled}>
      <InputGroup
        flex='1'>
        {type === 'password'
          ? <Flex
            flexDirection='column'
            flex='1'>
            <Input
              pr='4.5rem'
              type={showPassword ? 'text' : 'password'}
              placeholder={placeholder}
              defaultValue={defaultValue}
              isDisabled={isDisabled}
              {...register(name, {
                required: isRequired
              })}
              {...rest}
            />
            <FormLabel>{placeholder}</FormLabel>
            <InputRightElement width='4.5rem'>
              <IconButton
                variant="text"
                aria-label={showPassword ? 'Reveal password' : 'Mask password'}
                icon={showPassword ? <HiEye /> : <HiEyeOff />}
                onClick={onClickReveal}
                isDisabled={isDisabled}
              />
            </InputRightElement>
            {error && renderValidationError()}
          </Flex>
          : <Flex
            flexDirection='column'
            flex='1'>
            <Input
              placeholder={placeholder}
              defaultValue={defaultValue}
              isDisabled={isDisabled}
              size={{base: 'lg', md: 'lg', lg: 'lg'}}
              {...register(name, {
                required: isRequired
              })}
              {...rest} />
            <FormLabel>{placeholder}</FormLabel>
            {subActionComponent}
            {error && renderValidationError()}
          </Flex>}
      </InputGroup>
    </FormControl>
  );
};
export default ThemeInput;
