import { VALIDATION_ACTIONS } from "constants/_validation";
import * as yup from "yup";

export const required = (value: any) => value ? undefined : 'Required'

export const emailValidation = (value: string) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Invalid email address' : undefined

const maxLength = (max: number) => (value: string) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined

const minLength = (min: number) => (value: number) =>
  value && value < min ? `Must be at least ${min}` : undefined

export const minLength10Validation = minLength(10)

export const maxLength10Validation = maxLength(10)

export const phonePatternRegex = (value: string) => {
  return value && !/^[1-9]{1}[0-9]{9}$/i.test(value) ?
    'Invalid phone number' : undefined
}

const {
  EMAIL,
  PASSWORD,
  REFERRAL_CODE,
  PHONE_NUMBER,
  OTP,
  CLIENT_CODE,
  PIN,
  API_KEY,
  TOTP_SECRET_KEY
} = VALIDATION_ACTIONS;

export const LoginValidationSchema = () =>
  yup
    .object({
      email: yup
        .string()
        .email(EMAIL.INVALID)
        .required(EMAIL.REQUIRED),
      password: yup.string().required(PASSWORD.REQUIRED),
    })
    .required();

export const SignupValidationSchema = () =>
  yup
    .object({
      referralCode: yup
        .string()
        .required(REFERRAL_CODE.REQUIRED),
      email: yup
        .string()
        .email(EMAIL.INVALID)
        .required(EMAIL.REQUIRED),
      phoneNumber: yup
        .string()
        .required(PHONE_NUMBER.REQUIRED),
      password: yup
        .string()
        .required(PASSWORD.REQUIRED),
    })
    .required();

export const ResetPasswordValidationSchema = () =>
  yup
    .object({
      email: yup
        .string()
        .email(EMAIL.INVALID)
        .required(EMAIL.REQUIRED),
      otp: yup
        .string()
        .required(OTP.REQUIRED),
      password: yup
        .string()
        .required(PASSWORD.REQUIRED),
    })
    .required();

export const TestBrokerConnectionValidationSchema = () =>
  yup
    .object({
      clientCode: yup
        .string()
        .required(CLIENT_CODE.REQUIRED),
      pin: yup
        .string()
        .required(PIN.REQUIRED),
      apiKey: yup
        .string()
        .required(API_KEY.REQUIRED),
      totpSecretKey: yup
        .string()
        .required(TOTP_SECRET_KEY.REQUIRED),
    })
    .required();