import { Box, Center, Container, Stack, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { getWithDefault } from "utils";
import { CONSTANTS, TO } from "./../../constants";
import packageJson from './../../../package.json';
import './_styles.scss';

const AuthLayout = (props: any) => {
  const {
    children
  } = props;
  return (
    <Container className="am-splash" maxW="lg" py={{ base: '4', md: '20' }} px={{ base: '0', sm: '8' }} h='100%'>
      <Stack spacing="8">
        <Box
          py={{ base: '0', sm: '8' }}
          px={{ base: '4', sm: '10' }}
          bg={{ base: 'transparent', sm: 'white' }}
          boxShadow={{ base: 'none', sm: 'md' }}
          borderRadius={{ base: 'none', sm: 'xl' }}
          display='flex'
          flexDirection='column'
          height='100%'>
          {children}
        </Box>
      </Stack>
      <Stack mt={2} spacing='60px'>
        <Center
          flexDir='column'
          fontSize='md'>
          {/* <Link to={TO.TERMS_AND_CONDITIONS}>
            <Text color='blue'>{CONSTANTS.TERMS_AND_CONDITIONS}</Text>
          </Link> */}
          {/* <Text mt={2}>{CONSTANTS.COMPANY}</Text> */}
          <Text mt={2}>v{getWithDefault(packageJson, 'version', '')}</Text>
        </Center>
      </Stack>
    </Container>
  )
}

export default AuthLayout;