import { useState, useEffect, useRef } from 'react'
import { Box, Stack, Text, HStack, PinInput, PinInputField, Image, Divider, useToast, InputRightElement } from '@chakra-ui/react'
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons'
import { AuthLayout } from './../../layouts';
import AMButton from './../../components/generic/AMButton'
import ThemeInput from './../../components/form/ThemeInput';
import AuthService from './../../services/auth'
import { USER, P_RESET_PASS, TO, MESSAGES, P_GENERAL, TOAST_STATUS } from "./../../constants/";
import AMLogo from './../../assets/images/logo.png';
import { getWithDefault } from '../../utils';
import { ResetPasswordValidationSchema } from 'utils/_validations';

const OTP_PROPS = {
  RESEND_TIME_LIMIT: 120,
  MAX_ATTEMPTS: 3,
};

const MINUTES = 60;

// https://stackoverflow.com/a/2998874/1673761
const twoDigits = (num: number) => String(num).padStart(2, '0')

const ResetPassword = (props: any) => {

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  }: any = useForm({
    resolver: yupResolver(ResetPasswordValidationSchema())
  });

  // lib hooks
  let navigate = useNavigate();
  const toast = useToast();

  const [isOtpRequestSent, setOtpRequestSentFlag] = useState(false)
  const [secondsRemaining, setSecondsRemaining] = useState(OTP_PROPS.RESEND_TIME_LIMIT)
  const [attemptsRemaining, setAttemptsRemaining] = useState(OTP_PROPS.MAX_ATTEMPTS)
  const [status, setStatus] = useState(false)

  const secondsToDisplay = secondsRemaining % MINUTES
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / MINUTES
  const minutesToDisplay = minutesRemaining % MINUTES

  const onSubmit = (values: any) => {
    AuthService.resetPassword(values)
      .then((success: any) => {
        const { status } = success;
        if (status) {
          toast({
            description: getWithDefault(success, 'data', MESSAGES.PASSWORD_REQ_SUCCESS),
            status: TOAST_STATUS.SUCCESS
          })
          navigate(TO.LOGIN)
        }
      })
      .catch((error: any) => {
        const { response } = error;
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR
        })
      })
  }

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      }
    },
    status ? 1000 : null,
    // passing null stops the interval
  )

  // source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/
  function useInterval(callback: any, delay: any) {
    const savedCallback: any = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        let id = setInterval(tick, delay)
        return () => clearInterval(id)
      }
    }, [delay])
  }

  const sendOTP = () => {
    const email = getValues("email")
    if (attemptsRemaining <= 0) {
      toast({
        description: MESSAGES.MAX_OTP_ATTEMPS,
        status: TOAST_STATUS.ERROR
      })
      return;
    }
    AuthService.sendOtp(email)
      .then((success: any) => {
        const { status } = success;
        if (status) {
          setOtpRequestSentFlag(true)
          setAttemptsRemaining(attemptsRemaining - 1)
          setSecondsRemaining(OTP_PROPS.RESEND_TIME_LIMIT);
          setStatus(true);
          toast({
            description: getWithDefault(success, 'data', MESSAGES.OTP_SENT),
            status: TOAST_STATUS.SUCCESS
          })
        }
      })
      .catch((error: any) => {
        const { response } = error;
        toast({
          description: getWithDefault(response, 'data.message', MESSAGES.SOMETHING_BAD_HAPPENED),
          status: TOAST_STATUS.ERROR
        })
      })
  }

  return (
    <AuthLayout>
      <Stack spacing="4">
        <HStack
          alignItems='center'>
          <Box mb='3px'>
            <Link to={TO.LOGIN}>
              <ArrowBackIcon />
            </Link>
          </Box>
          <Image
            h={{ base: '12px', sm: '16px' }}
            src={AMLogo} />
        </HStack>
        <Divider />
        <Stack mb='4' spacing={{ base: '2', md: '3' }} textAlign="center">
          <Text
            fontSize={{ base: '3xl', sm: '4xl' }}
            fontWeight='semibold'>{P_RESET_PASS.HEADING}</Text>
          <Text
            fontSize={{ base: 'md', md: 'lg' }}
          >{P_RESET_PASS.SUB_HEADING}</Text>
        </Stack>
      </Stack>
      <Box as={"form"} onSubmit={handleSubmit(onSubmit)} noValidate>
        <Stack spacing={3}>
          <HStack>
            <ThemeInput
              isRequired
              name={USER.EMAIL.NAME}
              placeholder={USER.EMAIL.PLACEHOLDER}
              type={USER.EMAIL.TYPE}
              register={register}
              error={errors[USER.EMAIL.NAME]}
              isDisabled={isOtpRequestSent}
              subActionComponent={
                <InputRightElement width='4.5rem'>
                  <AMButton
                    label='Get OTP'
                    variant='ghost'
                    colorScheme="brand"
                    onClick={sendOTP}
                    isDisabled={!watch("email") || (isOtpRequestSent && secondsToDisplay !== 0)}
                  />
                </InputRightElement>
              }
            />
          </HStack>
          {attemptsRemaining <= 0 && <Stack
            alignItems='center'
            justifyContent='center'>
            <Text fontSize="sm">{MESSAGES.MAX_OTP_ATTEMPS}</Text>
          </Stack>}
          <HStack
            alignItems='center'
            justifyContent='center'>
            {(attemptsRemaining > 0 && isOtpRequestSent && secondsToDisplay !== 0) &&
              <>
                <Text fontSize="sm">{MESSAGES.NOT_RECEIVED_CODE}</Text>
                <Text fontSize="sm">{MESSAGES.RESEND_OTP} {twoDigits(minutesToDisplay)}:{twoDigits(secondsToDisplay)}</Text>
              </>}
          </HStack>
          <HStack alignItems='center' justifyContent='center'>
            <Controller
              name="otp"
              control={control}
              render={({ field }) => {
                return (
                  <PinInput
                    otp
                    mask
                    isDisabled={!isOtpRequestSent}
                    {...field}
                  >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                )
              }
              } />
          </HStack>
          <ThemeInput
            isRequired
            name={USER.NEW_PASSWORD.NAME}
            placeholder={USER.NEW_PASSWORD.PLACEHOLDER}
            type={USER.NEW_PASSWORD.TYPE}
            register={register}
            isDisabled={!isOtpRequestSent}
            error={isOtpRequestSent && errors[USER.NEW_PASSWORD.NAME]}
          />
        </Stack>
        <Box
          mt="4"
          display='flex'
          justifyContent='flex-end'>
          <AMButton
            rightIcon={<ArrowForwardIcon />}
            type="submit"
            label={P_GENERAL.RESET_PASSWORD}
            variant='round'
            colorScheme="brand"
            bgGradient='linear(to-r, brand.400, brand.500)' />
        </Box>
      </Box>
    </AuthLayout>
  )
}

export default ResetPassword;

// References:
// Timer: https://codesandbox.io/p/sandbox/react-countdown-demo-gtr4u?file=%2Fsrc%2FApp.js