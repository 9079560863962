import HTTP from "./http";
import {
  API_URL,
  URLS
} from './../constants';

const {
  SUBSCRIPTION
} = URLS;

const {
  PREFIX,
  BASE_URLS
} = SUBSCRIPTION;

const URL_PREFIX = PREFIX;

const {
  SEARCH,
  UPSERT,
  MY,
  PAUSE,
  RESUME
} = BASE_URLS;

const SubscriptionService = {
  async getSubscriptions({ page = 0, size = 10 }) {
    const url = `${API_URL}${URL_PREFIX}${SEARCH}`;
    const { data: response } = await HTTP.post(`${url}`, {
      page,
      size
    });
    return response;
  },

  async upsertSubscriptions(data) {
    const url = `${API_URL}${URL_PREFIX}${UPSERT}`;
    const { data: response } = await HTTP.put(`${url}`, data);
    return response;
  },

  async getSubscriptionBySlug(slug) {
    const url = `${API_URL}${URL_PREFIX}${slug}`;
    const { data: response } = await HTTP.get(`${url}`);
    return response;
  },

  async mySubscriptions() {
    const url = `${API_URL}${URL_PREFIX}${MY}`;
    const { data: response } = await HTTP.get(`${url}`);
    return response;
  },

  async pauseOrResumeSubscription(action, subscriptionSlug) {
    const ACTIONS = {
      PAUSE: PAUSE,
      RESUME: RESUME
    }
    const url = `${API_URL}${URL_PREFIX}${ACTIONS[action]}?subscriptionSlug=${subscriptionSlug}`;
    const { data: response } = await HTTP.post(`${url}`);
    return response;
  },

}

export default SubscriptionService