import axios from "axios";
import { CONSTANTS } from "./../constants/_general";
import { fetchDataFromStorage } from '../utils/_localStorage';

const HTTP = axios.create({
  baseURL: process.env.REACT_APP_NODE_ENV
});

HTTP.interceptors.request.use((config) => {
  if (typeof window !== "undefined") {
    const token = fetchDataFromStorage(CONSTANTS.AUTH.AUTH_TOKEN_KEY);
    config.headers[CONSTANTS.AUTH.AUTH_TOKEN_KEY] = token;
  }
  return config;
});

export default HTTP;